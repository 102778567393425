<template>
  <el-dialog
    title="新增"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
    width="1200px"
    class="adaptationModal"
    @close="onDialogClose">
    <el-form
      ref="form"
      class="form"
      label-width="100px"
      :model="safeCheckOrder"
      :rules="ruleValidate">
      <div class="vm-separate">
        <el-form-item label="保养类型" prop="maintType">
          <el-select
            v-model="safeCheckOrder.maintType"
            style="width: 100%"
            :placeholder="$t('common.pleaseSelect')">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 1" :label="$l('workOrder.elevator','电梯')" prop="elevatorId">
          <el-input v-model="safeCheckOrder.elevatorName" :placeholder="$t('common.pleaseSelect')" readonly>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="$refs.selectElevator.open()"></el-button>
          </el-input>
          <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" label="电梯" prop="elevatorName">
          <el-input
            v-model="safeCheckOrder.elevatorName"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 1" label="型号" prop="elevatorModel">
          <el-input
            v-model="safeCheckOrder.elevatorModel"
            :placeholder="isPlaceHolder"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" label="型号" prop="elevatorModel">
          <el-input
            v-model="safeCheckOrder.elevatorModel"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item v-if="safeCheckOrder.maintType === 1" label="设备号" prop="deviceCode">
          <el-input
            v-model="safeCheckOrder.deviceCode"
            :placeholder="isPlaceHolder"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" label="设备号" prop="deviceCode">
          <el-input
            v-model="safeCheckOrder.deviceCode"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 1" label="维保公司" prop="maintCom">
          <el-input
            v-model="safeCheckOrder.maintCom"
            :placeholder="isPlaceHolder"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" label="维保公司" prop="maintCom">
          <el-input
            v-model="safeCheckOrder.maintCom"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 1" :label="$t('elevator.elevatorType')" prop="elevatorType">
          <el-input
            v-model="safeCheckOrder.elevatorType"
            :placeholder="isPlaceHolder"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" :label="$t('elevator.elevatorType')" prop="elevatorType">
          <el-select
            v-model="safeCheckOrder.elevatorType"
            :placeholder="$t('elevator.elevatorType')"
            style="width: 100%">
            <el-option
              v-for="item in productTypeList"
              :key="item.label"
              :label="item.label"
              :value="item.label"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item v-if="safeCheckOrder.maintType === 1" label="维保人员" prop="maintEmp">
          <el-input
            v-model="safeCheckOrder.maintEmp"
            :placeholder="isPlaceHolder"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item v-if="safeCheckOrder.maintType === 2" label="维保人员" prop="maintEmp">
          <el-input
            v-model="safeCheckOrder.maintEmp"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('user.mail','区域')" prop="areaId">
          <el-select
            v-model="safeCheckOrder.areaId"
            :placeholder="$l('common.pleaseEnter','地域划分')"
            style="width: 100%"
            clearable>
            <el-option
              v-for="item in areaList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$l('user.mail','分公司')" prop="branchCompanyId">
          <el-select
            v-model="safeCheckOrder.branchCompanyId"
            :placeholder="$l('common.pleaseEnter','区域下所属公司')"
            style="width: 100%"
            clearable>
            <el-option
              v-for="item in branchCompanyList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item label="项目名称" prop="projectName">
          <el-input
            v-model="safeCheckOrder.projectName"
            :placeholder="$t('common.pleaseEnter')"
          ></el-input>
        </el-form-item>
        <el-form-item label="抽查人员" prop="checkEmp">
          <el-input
            v-model="safeCheckOrder.checkEmp"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="抽查日期" prop="checkDate">
          <el-date-picker
            v-model="safeCheckOrder.checkDate"
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </el-form-item>
      </div>
      <div>
        <table
          class="vm-table"
          style="width: 100%">
          <thead>
            <tr>
              <th>类别</th>
              <th>序号</th>
              <th>确认项目</th>
              <th>检查内容</th>
              <th>分值 <br/> 总分：{{allScore}}</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody v-for="(data,index) in tableData" :key="index">
            <tr v-for="(item,sonIndex) in data.safeCheckSettingList" :key="sonIndex">
              <td v-if="sonIndex===0" :rowspan="data.safeCheckSettingList.length" style="width: 150px">
                <span v-if="item.type===1">安全</span>
                <span v-if="item.type===2">基本条件</span>
                <span v-if="item.type===3">维保质量</span>
              </td>
              <td style="width: 100px">
                {{item.serialNo}}
              </td>
              <td>
                {{item.project}}
              </td>
              <td style="text-align: left">
                {{item.content}}
              </td>
              <td style="width: 100px">
                {{item.score}}
              </td>
              <td style="width: 200px">
                <el-radio-group v-model="item.isPass">
                  <el-radio :label="1" @change="handleChange(item)">合格</el-radio>
                  <el-radio :label="0" @change="handleChange(item)">不合格</el-radio>
                </el-radio-group>
              </td>
            </tr>
          </tbody>
        </table>
        <div>“★”项为否决项，该类项目不合格视为整梯不合格，80分以下视为整梯不合格；</div>
      </div>
      <div class="vm-separate" style="margin-top: 10px">
        <el-form-item label="总分">
          <el-input
            v-model="safeCheckOrder.totalScore"
            placeholder="请打分"
            readonly
            disabled></el-input>
        </el-form-item>
        <el-form-item label="结果">
          <el-input
            v-model="result"
            placeholder="请打分"
            readonly
            disabled></el-input>
        </el-form-item>
        <div v-if="safeCheckOrder.isPassCheck === true || safeCheckOrder.isPassCheck===''"></div>
        <el-form-item v-if="safeCheckOrder.isPassCheck === false" label="预计整改日期" prop="expectFinishTime">
          <el-date-picker
            v-model="safeCheckOrder.expectFinishTime"
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('yearCheck.note')" prop="note">
          <el-input v-model="safeCheckOrder.note" :placeholder="$t('common.pleaseEnter')" type="textarea"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="success"
        :loading="draftLoading"
        @click="handleDraft">草稿</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";

  const moduleName_elevator = "elevators";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        submitLoading: false,
        dialogVisible: false,
        draftLoading: false,
        activeTab: "first",
        isCheck: "",
        isShow: true,
        productTypeList: [],
        safeCheckOrder: {
          maintType: 1,
          status: "",
          note: "",
          elevatorName: "",
          elevatorId: 0,
          maintComId: 0,
          maintCom: "",
          maintEmp: "",
          deviceCode: "",
          elevatorModel: "",
          branchCompanyId: "",
          areaId: "",
          checkEmp: "",
          checkDate: "",
          totalScore: "",
          isPassCheck: "",
          expectFinishTime: "",
          projectName: "",
          elevatorType: "",
          safeCheckItemList: [],
          safeCheckOrderList: [
            {
              safeCheckSettingList: [{
                id: "",
                type: "",
                serialNo: "",
                content: "",
                project: "",
                score: "",
                isPass: "",
              }],
              type: "",
            },
          ],
        },
        sum: 0,
        result: "",
        areaList: [],
        branchCompanyList: [],
        totalSetting: [],
        tableData: [
          {
            safeCheckSettingList: [{
              id: "",
              type: "",
              serialNo: "",
              content: "",
              project: "",
              score: "",
              isPass: "",
            }],
            type: "",
          },
        ],
        isPlaceHolder: "请选择电梯",
        typeList: [
          {label: "自保", value: 1},
          {label: "分包", value: 2},
        ],
      };
    },
    computed: {
      allScore: function() {
        let sum = 0;
        if (this.tableData) {
          this.tableData.forEach(data => {
            if (data.safeCheckSettingList) {
              data.safeCheckSettingList.forEach(list => {
                if (parseInt(list.score)) {
                  sum += parseInt(list.score);
                }
              });
            }
          });
        }
        return sum;
      },
      ruleValidate() {
        return {
          elevatorName: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          deviceCode: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          elevatorModel: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          maintCom: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          maintEmp: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          elevatorType: {
            required: this.safeCheckOrder.maintType === 2,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          },
          expectFinishTime: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          projectName: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          areaId: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          branchCompanyId: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          checkEmp: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          checkDate: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
        };
      },
    },
    methods: {
      open(id, flag) {
        this.dialogVisible = true;
        this.getArea();
        this.getCompany();
        this.getProductTypeList();
        if (flag) {
          this.isShow = false;
        }
        if (id > 0) {
          this.getDraftData(id);
        } else {
          this.getData();
        }
      },
      getDraftData(id) {
        this.$http.get("safeCheckOrder/" + id).then(res => {
          this.safeCheckOrder = res.data;
          this.safeCheckOrder.safeCheckOrderList.forEach(data => {
            data.safeCheckSettingList.forEach(item => {
              this.totalSetting.push(item);
            });
          });
          this.safeCheckOrder.safeCheckItemList = this.totalSetting;
          this.result = this.safeCheckOrder.isPassCheck ? "合格" : "不合格";
          this.isPlaceHolder = this.safeCheckOrder.elevatorId ? "无" : "请选择电梯";
          this.tableData = this.safeCheckOrder.safeCheckOrderList;
        }).catch((error) => {

        });
      },
      getArea() {
        this.$http.get("branchAgency/type/1").then(response => {
          this.areaList = [];
          for (let area of response.data) {
            let item = {value: area.id, label: area.branchName};
            this.areaList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getCompany() {
        this.$http.get("branchAgency/type/2").then(response => {
          this.branchCompanyList = [];
          for (let area of response.data) {
            let item = {value: area.id, label: area.branchName};
            this.branchCompanyList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getProductTypeList() {
        this.$api.getList("elevatorProductTypes").then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            item = {label: productType.elevatorProductTypeName};
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      handleChange(item) {
        this.sum = 0;
        this.totalSetting.forEach(check => {
          if (check.score === "/") {
            if (check.isPass === 1) {
              this.sum += 0;
            }
          }
          if (check.score !== "/") {
            if (check.isPass === 1) {
              this.sum += parseInt(check.score);
            }
          }
        });


        let passList = this.totalSetting.filter(item => item.score === "/" && item.isPass === 0);
        this.safeCheckOrder.totalScore = passList.length > 0 ? 0 : this.sum;
        if (passList.length > 0 || this.sum < 80) {
          this.safeCheckOrder.isPassCheck = false;
          this.result = "不合格";
        }
        if (passList.length === 0 && this.sum > 80) {
          this.safeCheckOrder.isPassCheck = true;
          this.result = "合格";
        }

      },
      handleDraft() {
        this.safeCheckOrder.status = 2;
        this.draftLoading = true;
        this.$http.post("safeCheckOrder", this.safeCheckOrder)
          .then(() => {
            this.draftLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          })
          .catch(() => {
            this.draftLoading = false;
          });
      },
      handleSubmit() {
        // if (this.safeCheckOrder.elevatorId === 0) {
        //   this.$message.error("请选择电梯");
        //   return;
        // }
        let passList = this.totalSetting.filter(item => item.isPass === null || item.isPass === "");
        if (passList.length > 0) {
          this.$message.error("请给每一项打分");
          return;
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            this.safeCheckOrder.status = 1;
            this.safeCheckOrder.safeCheckItemList = this.totalSetting;
            this.$http.post("safeCheckOrder", this.safeCheckOrder)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });

      },
      getData() {
        this.$http.get("safeCheckSetting").then(res => {
          this.tableData = res.data;
          console.log(this.tableData);
          this.tableData.forEach(data => {
            data.safeCheckSettingList.forEach(item => {
              this.totalSetting.push(item);
            });
          });
          this.safeCheckOrder.safeCheckItemList = this.totalSetting;
          this.safeCheckOrder.totalScore = 100;
          this.safeCheckOrder.isPassCheck = true;
          this.result = "合格";
        }).catch((error) => {

        });
      },
      getElevator() {
        this.$api.getById(moduleName_elevator, this.safeCheckOrder.elevatorId).then(res => {
          let data = res.data;
          this.safeCheckOrder.maintComId = data.maintComId;
          this.safeCheckOrder.maintCom = data.maintEmpName;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.sum = 0;
        this.safeCheckOrder.elevatorId = "";
        this.safeCheckOrder.totalScore = "";
        this.safeCheckOrder.isPassCheck = "";
        this.totalSetting = [];
        this.$refs.form.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      onSelectElevator(row) {
        console.log(row);
        if (row.id !== this.safeCheckOrder.elevatorId) {
          this.safeCheckOrder.elevatorId = row.id;
          this.safeCheckOrder.elevatorName = row.name;
          this.safeCheckOrder.elevatorModel = row.model;
          this.safeCheckOrder.deviceCode = row.factoryNo;
          this.safeCheckOrder.maintCom = row.maintComName;
          this.safeCheckOrder.maintComId = row.maintComId;
          this.safeCheckOrder.maintEmp = row.maintEmpName;
          this.safeCheckOrder.elevatorType = row.elevatorProductTypeName;
          this.isPlaceHolder = "无";

        }
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
