<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="safeCheckOrder" @clear="clearDateRange">
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(false)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
        <el-button class="greenBtn" :loading="exportLoading" @click="exportExcel">
          {{ $l("elevator.exportExcel", "导出") }}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search label="完成状态">
          <el-select v-model="search.status" style="width: 100%" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search label="整改状态">
          <el-select v-model="search.changeStatus" style="width: 100%" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in changeStatusList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search label="保养类型">
          <el-select v-model="search.maintType" style="width: 100%" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search label="抽查结果">
          <el-select v-model="search.isPassCheck" style="width: 100%" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in isPassCheckList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('yearCheck.checkDate', '抽查日期')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
            @change="handleExpiredDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="checkNo" label="抽查编号" width="120" align="center"></el-table-column>
      <el-table-column prop="area" label="区域" width="100"></el-table-column>
      <el-table-column prop="childCom" label="分公司" width="100"></el-table-column>
      <el-table-column prop="projectName" show-overflow-tooltip label="项目名称"></el-table-column>
      <el-table-column prop="maintCom" show-overflow-tooltip label="维保公司"></el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip label="电梯名称"></el-table-column>
      <el-table-column prop="deviceCode" label="设备号" width="150"></el-table-column>
      <el-table-column prop="maintType" label="保养类型" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.maintType === 1">自保</span>
          <span v-if="scope.row.maintType === 2">分包</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkEmp" label="抽查人员" width="100" align="center"></el-table-column>
      <el-table-column prop="maintEmp" label="维保人员" width="100" align="center"></el-table-column>
      <el-table-column prop="checkDate" label="抽查日期" width="100" align="center"></el-table-column>
      <el-table-column prop="isPassCheck" label="抽查结果" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.isPassCheck === false">不合格</span>
          <span v-if="scope.row.isPassCheck === true">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="changeStatus" label="整改状态" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.changeStatus === 0 && scope.row.isPassCheck === false">待整改</span>
          <span v-if="scope.row.changeStatus === 1 && scope.row.isPassCheck === false">整改完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="完成状态" width="80" align="center">
        <template v-slot="scope">
          <el-tag type="success" v-if="scope.row.status === 1">完成</el-tag>
          <el-tag type="info" v-else>草稿</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="100" fixed="right">
        <template v-slot="scope">
          <el-button class="successBtn" type="text" @click="$refs.info.open(scope.row.id)">查看</el-button>
          <el-button v-if="scope.row.status === 2" type="text" @click="$refs.editPage.open(scope.row.id, true)">
            编辑
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <safe-check-order-edit ref="editPage" @save-success="getList(-1)"></safe-check-order-edit>
      <safe-check-record ref="info"></safe-check-record>
    </vm-table>
  </div>
</template>

<script>
import SafeCheckOrderEdit from "@/views/safeCheckOrder/SafeCheckOrderEdit";
import SafeCheckRecord from "@/views/safeCheckOrder/SafeCheckRecord";

export default {
  components: { SafeCheckOrderEdit, SafeCheckRecord },
  data() {
    return {
      loading: true,
      exportLoading: false,
      search: {
        filter: "",
        maintType: "",
        isPassCheck: "",
        startTime: "",
        endTime: "",
        status: "",
        changeStatus: "",
      },
      dateRange: [],
      typeList: [
        { label: "自保", value: 1 },
        { label: "分包", value: 2 },
      ],
      isPassCheckList: [
        { label: "合格", value: true },
        { label: "不合格", value: false },
      ],
      statusList: [
        { label: "完成", value: 1 },
        { label: "草稿", value: 2 },
      ],
      changeStatusList: [
        { label: "整改完成", value: 1 },
        { label: "待整改", value: 0 },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    exportExcel() {
      this.exportLoading = true;
      let params = {
        startTime: this.dateRange != null ? this.dateRange[0] : "",
        endTime: this.dateRange != null ? this.dateRange[1] : "",
        filter: this.search.filter,
        maintType: this.search.maintType,
        isPassCheck: this.search.isPassCheck,
        changeStatus: this.search.changeStatus,
      };
      this.$api
        .exportFile("safeCheckOrder/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "抽查信息.xlsx";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    clearDateRange() {
      this.dateRange = [];
    },
    handleExpiredDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleDelete(row) {
      this.$confirm("确定删除吗?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("safeCheckOrder", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
  },
};
</script>

<style scoped></style>
