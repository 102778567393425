<template>
  <el-dialog
    title="抽查信息"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
    width="1300px"
    top="5vh">
    <div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>保养类型：{{safeCheckOrder.maintType === 1 ? "自保" : "分包"}}</span>
        <span>电梯名称：{{safeCheckOrder.elevatorName}}</span>
        <span>梯种：{{safeCheckOrder.elevatorType}}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>型号：{{safeCheckOrder.elevatorModel}}</span>
        <span>设备号：{{safeCheckOrder.deviceCode}}</span>
        <span>维保公司：{{safeCheckOrder.maintCom}}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>维保人员：{{safeCheckOrder.maintEmp}}</span>
        <span>区域：{{safeCheckOrder.area}}</span>
        <span>分公司：{{safeCheckOrder.childCom}}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>项目名称：{{safeCheckOrder.projectName}}</span>
        <span>抽查人员：{{safeCheckOrder.checkEmp}}</span>
        <span>抽查日期：{{safeCheckOrder.checkDate}}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>总分：{{safeCheckOrder.totalScore}}</span>
        <span>结果：{{safeCheckOrder.isPassCheck ? "合格" : "不合格"}}</span>
        <span v-if="!safeCheckOrder.isPassCheck">预计整改日期：{{safeCheckOrder.expectFinishTime}}</span>
        <sapn v-if="safeCheckOrder.isPassCheck"></sapn>
      </div>
      <div v-if="!safeCheckOrder.isPassCheck" class="vm-separate" style="margin-bottom: 10px">
        <span
          v-if="!safeCheckOrder.isPassCheck && safeCheckOrder.status!==2">整改状态：{{safeCheckOrder.changeStatus === 1 ? "整改完成" : "待整改"}}</span>
        <span
          v-if="safeCheckOrder.changeStatus === 1 && safeCheckOrder.finishTime">整改完成时间：{{safeCheckOrder.finishTime}}</span>
        <div v-if="safeCheckOrder.changeStatus === 1 && safeCheckOrder.finishTime"></div>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>备注：{{safeCheckOrder.note}}</span>
      </div>
      <div style="height: 40vh;overflow: auto">
        <table
          class="vm-table"
          style="width: 100%">
          <thead>
            <tr>
              <th>类别</th>
              <th>序号</th>
              <th>确认项目</th>
              <th>检查内容</th>
              <th>分值 <br/> 总分：{{allScore}}</th>
              <th>是否合格</th>
            </tr>
          </thead>
          <tbody v-for="(data,index) in safeCheckOrder.safeCheckOrderList" :key="index">
            <tr v-for="(item,sonIndex) in data.safeCheckSettingList" :key="sonIndex">
              <td v-if="sonIndex===0" :rowspan="data.safeCheckSettingList.length" style="width: 150px">
                <span v-if="item.type===1">安全</span>
                <span v-if="item.type===2">基本条件</span>
                <span v-if="item.type===3">维保质量</span>
              </td>
              <td style="width: 100px">
                {{item.serialNo}}
              </td>
              <td>
                {{item.project}}
              </td>
              <td style="text-align: left">
                {{item.content}}
              </td>
              <td style="width: 100px">
                {{item.score}}
              </td>
              <td style="width: 200px">
                <span>{{item.isPass === 1 ? "合格" : "不合格"}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="height: 20px;width: 100%"></div>
      <el-divider content-position="left">签字确认</el-divider>
      <div class="vm-separate" style="margin-bottom: 20px;margin-top: 30px">
        <div>
          抽查人员确认：
          <div style="position: relative;top:-18px;left: 100px;width: 200px">
            <el-image
              v-if="safeCheckOrder.checkEmpConfirm"
              style="max-width:80px;height:auto"
              :src="safeCheckOrder.checkEmpConfirm"
              fit="contain"
              lazy
              :preview-src-list="[safeCheckOrder.checkEmpConfirm]">
            </el-image>
          </div>
        </div>
        <div>
          维保人员确认：
          <div style="position: relative;top:-18px;left: 100px;width: 200px">
            <el-image
              v-if="safeCheckOrder.maintEmpConfirm"
              style="max-width:80px;height:auto"
              :src="safeCheckOrder.maintEmpConfirm"
              fit="contain"
              lazy
              :preview-src-list="[safeCheckOrder.maintEmpConfirm]">
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        safeCheckOrder: {
          maintType: 1,
          elevatorName: "",
          elevatorId: 0,
          maintComId: 0,
          maintCom: "",
          maintEmp: "",
          deviceCode: "",
          elevatorModel: "",
          childCom: "",
          area: "",
          checkEmp: "",
          checkDate: "",
          totalScore: "",
          isPassCheck: "",
          expectFinishTime: "",
          checkEmpConfirm: "",
          maintEmpConfirm: "",
          elevatorType: "",
          projectName: "",
          changeStatus: "",
          status: "",
          finishTime: "",
          note: "",
          safeCheckOrderList: [
            {
              safeCheckSettingList: [{
                id: "",
                type: "",
                serialNo: "",
                content: "",
                project: "",
                score: "",
                isPass: "",
              }],
              type: "",
            },
          ],
        },
        sum: 0,
      };
    },
    computed: {
      allScore: function() {
        let sum = 0;
        this.safeCheckOrder.safeCheckOrderList.forEach(data => {
          data.safeCheckSettingList.forEach(list => {
            if (parseInt(list.score)) {
              sum += parseInt(list.score);
            }
          });
        });
        return sum;
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.getData(id);
      },
      getData(id) {
        this.$http.get("safeCheckOrder/" + id).then(res => {
          console.log(res.data);
          this.safeCheckOrder = res.data;
        }).catch((error) => {

        });
      },
    },
  };
</script>

<style scoped>
.bottom {
  margin-bottom: 5px;
}
</style>
